import { useCallback, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'

import { bookStylist } from '@api/client/deal/book-stylist'
import { getUserInfo } from '@api/get-user-info'
import { authSelector } from '@store/auth'
import { useAppSelector } from '@store/store'
import { setUserInfo, userSelector } from '@store/user'
import { Spinner } from '@ui/spinner'
import { handleToast } from '@utils/handle-toast'
import TagManager from 'react-gtm-module'

export function ClientPaymentSuccessPage() {
	const stylistIdToBook = Number(localStorage.getItem('stylistId'))
	const { token } = useAppSelector(authSelector)
	const { userInfo } = useAppSelector(userSelector)

	const [isLoading, setIsDataLoading] = useState(false)
	const [paymentInfo, setPaymentInfo] = useState<{
		stylistId?: number
		isStylistBooked?: boolean
	}>({
		stylistId: stylistIdToBook,
		isStylistBooked: false,
	})
	const handleBookStylist = useCallback(
		async (id: number) => {
			setIsDataLoading(true)

			const isStylistBooked = await bookStylist({ token, stylistId: id })

			if (!isStylistBooked) {
				handleToast({ content: 'Something went wrong with booking' })
			}

			const updatedUserInfo = await getUserInfo({
				token,
				id: userInfo.id,
			})

			if (!updatedUserInfo) {
				handleToast({ content: 'Something went wrong with user info' })
			}

			updatedUserInfo && setUserInfo(updatedUserInfo)
			setPaymentInfo({ stylistId: id, isStylistBooked })
			setIsDataLoading(false)
		},
		[token, userInfo.id]
	)

	useEffect(() => {
		TagManager.dataLayer({
			dataLayer: {
				event: 'payment',
				price: userInfo.client?.tariff?.price || undefined,
				status: 'success'
			}
		})

		if (isLoading || paymentInfo.isStylistBooked || !stylistIdToBook) return

		handleBookStylist(stylistIdToBook)
	}, [])

	if (isLoading) {
		return <Spinner />
	}

	if (!isLoading && paymentInfo.isStylistBooked) {
		return (
			<Navigate
				to="/success-book"
				state={{ stylistId: paymentInfo.stylistId }}
			/>
		)
	}

	return null
}
