import LoginBG from '@assets/loginBG.png'
import { screenSize } from '@styles/screen-sizes'
import { Block } from '@ui/block'
import { Checkbox, Row } from 'antd'
import styled from 'styled-components'
import { colors } from './../../styles/colors'
import {
	fontPlayfair,
	fontRoboto,
	fontTenorSans,
	regular10,
	regular16,
	regular26,
	regular36,
	regular40,
} from './../../styles/typography'

export const PageWrapper = styled(Block)`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	background: url(${LoginBG}), ${colors.white};
	background-size: cover;
	background-repeat: no-repeat;
	background-position: bottom;
	height: 100%;
`

export const H1Styled = styled.h1`
	${fontPlayfair}
	font-style: normal;
	font-weight: 400;
	font-size: 62px;

	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	letter-spacing: 1.24px;
	margin: 20px 0;

	flex-wrap: wrap;

	& i {
		color: rgba(255, 12, 99, 1);
	}

	@media (${screenSize.antXs}) {
		font-size: 40px;
	}
`

export const DescriptionText = styled.div`
	${fontTenorSans}
	${regular26}
  display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 0.005em;
	margin-bottom: 20px;
`

export const RowStyled = styled(Row)`
	flex-grow: 1;
`

export const Wrapper = styled.div`
	max-width: 389px;
	width: 100%;
	background: #ffe8e8;
	border-radius: 20px;
	padding: 25px;
`

export const InnerCont = styled.div`
	background: ${colors.white};
	border-radius: 20px;
	padding: 25px;
	height: 100%;
	display: flex;
	flex-flow: column;
`

export const RateTitle = styled.div`
	${fontTenorSans}
	${regular26}
  text-align: center;
	color: #383838;
`

export const RateDescription = styled.div`
	${fontTenorSans}
	${regular16}
  text-align: center;
	letter-spacing: 0.005em;
	white-space: pre-wrap;
	flex: 1;
`

export const RatePrice = styled.div`
	${fontPlayfair}
	${regular40}
  align-items: center;
	letter-spacing: 0.005em;
	text-align: center;
`

export const OldPrice = styled.div`
	${fontPlayfair}
	${regular36}
	color: ${colors.blackTr48};
	align-items: center;
	text-align: center;
	text-decoration: line-through;
`

export const CheckboxStyled = styled(Checkbox)`
	${fontRoboto}
	${regular10}
	color: ${colors.blackTr64};
`

export const TermsBlock = styled.span`
	${fontRoboto}
	${regular10}
	color: ${colors.blackTr64};
	margin-left: 24px;

	& a {
		color: ${colors.mainThemeColor};
	}
`

export const RateBlockWrapper = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	column-gap: 48px;
	row-gap: 24px;

	padding: 48px 20px;

	@media (${screenSize.antXs}) {
		padding: 25px 20px;
	}
`
