import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { store } from './store/store'

import App from './app'
import * as serviceWorker from './serviceWorker'
import { GlobalStyle } from './styles'

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
	<Provider store={store}>
		<React.StrictMode>
			<GlobalStyle />
			<BrowserRouter>
				<ToastContainer />
				<App />
				<div
					style={{
						position: 'absolute',
						bottom: '0',
						textAlign: 'center',
						width: '100%',
					}}
				>
					{`v.${process.env.REACT_APP_VERSION}`}
				</div>
			</BrowserRouter>
		</React.StrictMode>
	</Provider>
)

serviceWorker.unregister()
