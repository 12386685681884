import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import {
	capsulesSelector,
	setCapsule,
	setIsCapsuleLoading,
} from '@store/capsules'
import { clothesSelector, setCapsuleClothesList } from '@store/clothes'
import { settingsSelector } from '@store/settings'
import { useAppDispatch, useAppSelector } from '@store/store'
import {
	ImageItemType,
	NavigationButtonInfo,
	ShowPageType,
} from '@typings/types'
import { getCapsuleInfo } from '@api/capsule/get-capsule'
import { authSelector } from '@store/auth'
import { PROD_DOMAIN } from '@typings/constants'
import { updateCapsule } from '@api/capsule/update-capsule'

export function useCapsulePreviewPage() {
	const dispatch = useAppDispatch()
	const { state, pathname } = useLocation()
	const navigate = useNavigate()

	const { token } = useAppSelector(authSelector)
	const { capsule, isCapsuleLoading } = useAppSelector(capsulesSelector)
	const { settings } = useAppSelector(settingsSelector)
	const { capsuleClothesList } = useAppSelector(clothesSelector)

	const capsuleId = state?.capsuleId || undefined

	const [isHomepageRadioActive, setHomepageRadioActive] =
		useState<boolean>(false)
	const [imageItems, setImageItems] = useState<ImageItemType[]>([])
	const [isShowAllCategories, setIsShowAllCategories] = useState(false)
	const [isShortCategoriesList, setIsShortCategoriesList] = useState(true)
	const [clientLabel, setClientLabel] = useState('')
	const [clientAvatarUrl, setClientAvatarUrl] = useState('')

	const currentPage: ShowPageType =
		pathname === `/capsules/${capsuleId}/edit` ? 'edit' : 'preview'

	const categoriesToShow = useMemo(() => {
		const categories = capsule.summary?.category
		if (!categories) return []

		return isShortCategoriesList
			? Object.entries(categories).slice(0, 6)
			: Object.entries(categories)
	}, [capsule.summary?.category, isShortCategoriesList])

	const loadCapsuleInfo = useCallback(async () => {
		dispatch(setIsCapsuleLoading(true))
		const data = await getCapsuleInfo({ token, capsuleId })

		if (data) {
			dispatch(setCapsule(data))

			const items = data.items || []
			const imageItems = items
				.map((item) => ({
					id: item.coverPhoto?.id || 0,
					url: PROD_DOMAIN + item.coverPhoto?.urls.M,
					previewUrl: PROD_DOMAIN + item.coverPhoto?.urls.L,
					isCover: false,
					title: item.title,
					brand: item.brand,
					price: item.price,
					currency: data.summary?.priceValute,
					itemId: item.id,
				}))
				.filter(Boolean)
			setImageItems(imageItems)
			setHomepageRadioActive(!!data.params?.showOnHomepage?.[0])
			dispatch(setCapsuleClothesList(data.items))
			dispatch(setIsCapsuleLoading(false))
			setClientLabel(
				data?.linkedClient?.fullName || data?.linkedClient?.id.toString() || ''
			)
			setClientAvatarUrl(data?.linkedClient?.params.photo?.[0].urls.S || '')
			data.summary?.category &&
				setIsShowAllCategories(Object.entries(data.summary.category).length > 6)
			return
		}
		dispatch(setIsCapsuleLoading(false))
		navigate('/capsules')
	}, [capsuleId, dispatch, navigate, token])

	useEffect(() => {
		loadCapsuleInfo()
	})

	const handleEditClick = useCallback(() => {
		navigate(`/capsules/${capsuleId}/edit`, {
			state: {
				capsuleId: capsuleId,
			},
		})
	}, [])

	const navigationButtons: NavigationButtonInfo[] = useMemo(
		() => [
			{
				title: 'Preview',
				iconName: 'previewIcon',
				showListType: 'preview',
				handleClick: () => {},
			},
			{
				title: 'Edit',
				iconName: 'editIcon',
				showListType: 'edit',
				handleClick: handleEditClick,
			},
		],
		[handleEditClick]
	)

	const homepageRadioHandleClick = async (e: any) => {
		setHomepageRadioActive(!isHomepageRadioActive)
		await updateCapsule(
			JSON.stringify({
				params: {
					showOnHomepage: !isHomepageRadioActive,
				},
			}),
			capsule.id,
			token
		)
	}

	const handleToggleCategoriesList = () => {
		setIsShortCategoriesList((prev) => !prev)
	}

	return {
		isShowAllCategories,
		clientLabel,
		clientAvatarUrl,
		capsule,
		capsuleClothesList,
		currentPage,
		imageItems,
		navigationButtons,
		homepageRadioHandleClick,
		isHomepageRadioActive,
		settings,
		isCapsuleLoading,
		categoriesToShow,
		isShortCategoriesList,
		handleToggleCategoriesList,
	}
}
