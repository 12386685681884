import { Col, Row } from 'antd'

import { StyleboardElement } from '@typings/types'
import { PhotoItem } from './photo-item/photo-item'
import { EmptyBlock } from './photo-list-styled'

type Props = {
	photos: (StyleboardElement | null)[]
}

export function PhotosList({ photos }: Props) {
	return (
		<Row
		gutter={{xs: 8, sm: 12, md: 24, lg: 48 }}
			justify={'start'}
			style={{ width: '100%', margin: 0 }}
		>
			{photos.map((photo, index) => {
				if (photo === null)
					return (
						<Col key={index} xxl={{ span: 6 }} lg={{ span: 8 }} sm={{ span: 12, offset: 0 }} xs={{ span: 22, offset: 1 }} style={{marginBottom: 24}}>
							<EmptyBlock />
						</Col>
					)

				return (
					<Col key={index} xxl={{ span: 6 }} lg={{ span: 8 }} sm={{ span: 12, offset: 0 }} xs={{ span: 22, offset: 1 }} style={{marginBottom: 24}}>
						<PhotoItem photo={photo} />
					</Col>
				)
			})}
		</Row>
	)
}
