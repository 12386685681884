import { Dropdown, Row } from 'antd'
import { Link } from 'react-router-dom'

import { Block, Button, Icon, SiteLogo, Swap } from '@ui/index'
import { HeaderCount } from '../../header-count/header-count'
import { HeaderProps } from '../../types'
import { useHeader } from '../../use-header'
import { ProfileWrapper, RightBlock, Wrapper } from './header-mobile-styled'

export function HeaderMobile({ onMenuToggle }: HeaderProps) {
	const { token, counts, imageUrl, handleProfile, handleLogOut } =
		useHeader()

	return (
		<Row>
			<Wrapper>
				<Block onClick={token ? onMenuToggle : () => {}}>
					{token && <Icon name="mobileMenuIcon" />}
				</Block>

				<Block isFlexBlock isContentCentered>
					<SiteLogo color="white" size={24} />
				</Block>
				<RightBlock isFlexBlock>
					{token &&
						counts.map((item, index) => <HeaderCount key={index} {...item} />)}
					<Swap
						is={!token}
						isSlot={
							<Link to="/login">
								<ProfileWrapper>
									<Icon name="userIcon" color="white" size={20} />
								</ProfileWrapper>
							</Link>
						}
					>
						<Dropdown
							menu={{
								items: [
									{
										key: '1',
										label: (
											<Button skin="grey-link" size="m" onClick={handleProfile}>
												Profile
											</Button>
										),
									},
									{
										key: '2',
										label: (
											<Button skin="grey-link" size="m" onClick={handleLogOut}>
												Log Out
											</Button>
										),
									},
								],
							}}
							placement="bottomRight"
							arrow={{ pointAtCenter: true }}
							trigger={token ? ['click'] : []}
						>
							<ProfileWrapper imageUrl={imageUrl}>
								{!imageUrl && <Icon name="userIcon" color="white" size={20} />}
							</ProfileWrapper>
						</Dropdown>
					</Swap>
				</RightBlock>
			</Wrapper>
		</Row>
	)
}
