import { MediaLayout } from '@ui/media-layout/media-layout'
import { AppLayoutDesktop } from './layout/desktop/app-layout-desktop'
import { AppLayoutMobile } from './layout/mobile/app-layout-mobile'

export function AppLayout() {
	return (
		<MediaLayout
			mobileSlot={<AppLayoutMobile />}
			desktopSlot={<AppLayoutDesktop />}
		/>
	)
}
