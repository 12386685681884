import { Col, Row } from 'antd'
import { useNavigate } from 'react-router-dom'

import {
	BgCont,
	BlockStyled,
	ButtonStyled,
	Container,
	ImageBlock,
	InterviewMsg,
	NoteText,
	TextBlockCol,
} from './client-book-success-page-styled'

export function ClientBookSuccessPage() {
	const navigate = useNavigate()

	return (
		<>
			<BgCont></BgCont>
			<Container>
				<BlockStyled>
					<TextBlockCol>
						Awesome! <br />
						Your booking request has been sent
					</TextBlockCol>
					<InterviewMsg>
						The stylist typically confirms
						<br /> within 24 hrs, often sooner
					</InterviewMsg>
					<Row gutter={[24, 28]} justify={{xs: 'center', sm: 'center', lg: 'start'}} align={{xs: 'middle'}}>
						<Col 
							xs={{span: 22, order: 1, }} 
							sm={{span: 24}} 
							xl={{span: 12, order: 0}}
							>
							<ButtonStyled
								isSubmit
								skin="dark"
								onClick={() => {
									navigate('/client-profile')
								}}
								style={{ padding: '16px 36px'}}
								isFitContent
							>
								Go to your profile
							</ButtonStyled>
						</Col>
						<Col xs={{span: 22, order: 0}} sm={{span: 16}} xl={{span: 12, order: 1}}>
							<NoteText>
								use this time to complete <br />
								your profile set up
							</NoteText>
						</Col>
					</Row>
				</BlockStyled>
				<ImageBlock />
			</Container>
		</>
	)
}
