import styled, { css } from 'styled-components'
import { Button } from '@ui/button'
import { colors } from '@styles/colors'

export const ImageCont = styled.div<{ imageUrl?: string }>`
	width: 268px;
	height: 402px;

	${({ imageUrl }) =>
		imageUrl &&
		css`
			background-image: url(${imageUrl});
			box-shadow: none;
		`}
	background-color: ${colors.blackTr5};
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`

export const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;

	max-width: 268px;
	min-height: 293px;
	width: 100%;
	cursor: pointer;
	border-radius: 20px;
	overflow: hidden;
	margin: 0 auto;

	& > .cover-button[data-isactive='false'] {
		opacity: 0;
	}

	&:hover {
		${ImageCont} {
			box-shadow: 0px 6px 32px ${colors.blackTr8};
		}

		& .hidden-button,
		.cover-button {
			opacity: 1;
		}
	}
`

export const IconWrapper = styled.div`
	position: absolute;
	top: 8px;
	right: 8px;
	padding: 12px;
	cursor: pointer;
	z-index: 10;
	opacity: 0;
`

export const DeleteFormCapsule = styled(Button)`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px;
	gap: 4px;

	position: absolute;
	top: 20px;
	right: 20px;

	background: linear-gradient(180deg, #ff0c63 0%, #ff5656 100%);
	border-radius: 8px;

	opacity: 0;
`
