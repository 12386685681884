import { useNavigate } from 'react-router-dom'

import { addQuizAnswer } from '@api/quiz/add-quiz-answer'
import { getQuizQuestions } from '@api/quiz/get-quiz-questions'
import { authSelector } from '@store/auth'
import {
	QuizSelector,
	setIsLoading,
	setQuizQuestions,
	setQuizStep,
} from '@store/quiz'
import { useAppDispatch, useAppSelector } from '@store/store'
import { userSelector } from '@store/user'
import { Button } from '@ui/index'
import { handleToast } from '@utils/handle-toast'

import { ButtonContainer, Container } from './quiz-footer-styled'

const REQUIRED_ANSWERS = [1, 19]

export function QuizFooter() {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const { token } = useAppSelector(authSelector)
	const { userInfo } = useAppSelector(userSelector)
	const { questions, currentStep, answers, isLoading } =
		useAppSelector(QuizSelector)

	const stepNumber = questions[currentStep - 1]?.step_num || 0

	const isAnswerRequired =
		userInfo.role === 'stylist'
			? !answers[currentStep]?.length
			: REQUIRED_ANSWERS.includes(stepNumber) && !answers[currentStep]?.length
	const isNextButtonDisabled = isAnswerRequired || isLoading
	const currentAnswer = answers[currentStep]
	const isLastStep = currentStep === questions.length

	const handleNextStep = async () => {
		if (isLoading) return
		dispatch(setIsLoading(true))
		const result = await addQuizAnswer({
			token,
			step: currentStep,
			answers: Array.isArray(currentAnswer) ? currentAnswer : [currentAnswer],
		})

		if (!result) {
			handleToast({ content: 'Error while saving answer' })
			dispatch(setIsLoading(false))
			return
		}

		const updatedQuestions = await getQuizQuestions({ token })

		if (!updatedQuestions) {
			handleToast({ content: 'Error while saving answer' })
			dispatch(setIsLoading(false))
			return
		}

		dispatch(setQuizQuestions(updatedQuestions))

		if (isLastStep && !token) {
			dispatch(setQuizStep(888))
			dispatch(setIsLoading(false))
			return
		}

		if (isLastStep && token) {
			dispatch(setQuizStep(999))
			dispatch(setIsLoading(false))
			navigate('/quiz-results')
			return
		}

		dispatch(setQuizStep(currentStep + 1))
		dispatch(setIsLoading(false))
	}

	if (currentStep === 0 || currentStep === 888 || currentStep === 999)
		return null

	return (
		<Container>
			<ButtonContainer>
				{currentStep > 0 && (
					<Button
						onClick={
							isLoading
								? () => {}
								: () => dispatch(setQuizStep(currentStep - 1))
						}
						skin="dark-link"
						isDisabled={isLoading}
					>
						Back
					</Button>
				)}
			</ButtonContainer>
			{/*answerComment && <CommentBlock>{answerComment}</CommentBlock>*/}
			<ButtonContainer>
				<Button
					onClick={isNextButtonDisabled ? () => {} : handleNextStep}
					skin="dark"
					isDisabled={isNextButtonDisabled}
				>
					{!isLastStep ? 'Next' : 'Finish'}
				</Button>
			</ButtonContainer>
		</Container>
	)
}
