// import { useGetCurrency } from '@hooks/use-get-currency'
import {
	Brand,
	Container,
	ImageCont,
	Price,
	Title,
} from './clothes-item-styled'

import { ItemInfo } from '@typings/types'
import { PROD_DOMAIN } from '@typings/constants'

type Props = {
	clothItem: ItemInfo
	onClick: (id: number) => void
	priceValute?: string
}

export function ClothesItem({ clothItem, onClick, priceValute }: Props) {
	const userPhotoUrl = PROD_DOMAIN + clothItem.coverPhoto?.urls?.M

	return (
		<Container
			onClick={() => onClick(clothItem.id)}
			data-created={clothItem.created_at}
		>
			<ImageCont imageUrl={userPhotoUrl} />
			<Title>{clothItem.title}</Title>
			<Brand>{clothItem.brand}</Brand>
			<Price>
				{clothItem.price &&
					`${priceValute ? priceValute : ''} ${clothItem.price}`}
			</Price>
		</Container>
	)
}
